html, body {
	font: 62.5% 'Source Sans Pro', sans-serif;
	background-color: #f0f1f3;
	color: #333;
	margin: 0;
}
body {
	font-size: 1.6rem;
}

.file .icon { color: #004a60; }
.actual-file .icon {
	color: #007DA3;

	.fab {
		color: #4DA4BF;
	}
}

.pinned-directories > div,
.files {
	box-shadow: 2px 3px 4px -2px rgba(0,0,0,0.2);
}

.path-info {
	max-width: 980px;
	width: 100%;
	display:flex;
	justify-content: flex-end;
	font-size: 0.8em;
	color:#777;
	margin-bottom: 2px;
	margin-right:10px;

	> div {
		> span {
			padding-left: 6px;
			.value {
				padding-left: 4px;
			}

			&.root-size {
				&:before {
					padding-right: 10px;
					content: '/';
				}
			}
		}
	}
}

.site > header {
	background: #004a60 linear-gradient(#005a76, #004a60);
	border-bottom: 0.1rem solid black;
	color: white;
	padding: 1rem 2rem;
	font-size: 2.4rem;
	text-align: center;

	> div {
		margin: auto;
		max-width: 980px;
		button {
			float: right;
		}
	}

	a, a:visited {
		color: white;
		text-decoration: none;
	}
}

.site > footer > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.site > footer .item {
	padding: 0.25em;
}

@media screen and (max-width: 470px) {
	.site > footer > div {
		flex-direction: column;
	}
}

.breadcrumbs,
.no-breadcrumbs {
	padding: 1em 0.5em 0;
}

.no-breadcrumbs {

	color: #777;
	margin: 10px 0 10px;

	.quote {
		font-style: italic;
		font-size: 0.8em;
	}

	.author {
		color: lighten(#777, 20%);
		margin-left: 10px;
		font-size: 0.8em;
	}
}

.breadcrumbs {
	svg.icon {
		height: 1em;
		vertical-align: -12.5%;
	}

	.separator {
		color: #555;
		padding: 0 3px;
	}

	a {
		text-decoration: none;
	}
}

.site {
	display: flex;
	flex-direction: column;
	min-height: 90vh;
}

.directory.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	flex: 1;
}

.breadcrumbs, .files, .pinned-directories {
	max-width: 980px;
	width: 100%;
	box-sizing: border-box;
}

@media screen and (max-width: 980px) {
	.files {
		border-left: none;
		border-right: none;
		border-radius: 0;
	}
}

.files {

	background-color: white;
	border: 1px solid #e0e2e4;
	border-radius: 0.35rem;

	.meta {

		margin: 0;
		padding: 0;

		dl, dt, dd {
			margin: 0;
			display: inline-block;
		}
		dl {
			margin: 0 10px 10px 25px;
			font-size: 0.8em;
			color: #777;
		}
		dt {
			font-weight: bold;
			&:after { content:':'; }
		}
		dd {
			margin-right: 20px;
		}

		@media only screen and (max-width:768px) {
			dt, dd { display: block; float: left; }
			dt { clear: left; margin-right: 10px; }
		}

	}

	.file {
		line-height: 20px;

		td {
			border-top: 1px solid #eaecef;
			padding: 6px 3px;
		}

		// no border for first file
		&:first-of-type td { border: none; }

		// no border for mobile, since it wraps
		@media only screen and (max-width:768px) {
			td {
				border: none;
			}
		}

		.icon {
			width: 17px;
			padding-left: 1em;

			svg {
				height: 1em;
				vertical-align: -11%;

				path {
					fill: #395674;
				}
			}
		}

		.name {
			word-break: break-word;

			a {
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.pinned-directories > div .name a {
	text-decoration: none;
}

.pinned-directories > div .name a:hover,
.pinned-directories > div .name a:focus {
	text-decoration: underline;
}

.files .file.disabled a,
.files .file.disabled time{
	color: #AAA;
}
.files .file.disabled .icon svg path {
	fill: #888;
}

.files .empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1em;
	color: #555;
}
.files .empty svg {
	width: 25%;
}
.files .empty .information {
	font-size: 1.5em;
}

.files .warn {
	display: table-caption;
	background-color: #fff8db;
	color: #b58105;
	padding: 0.5em 1em;
}
.files .warn svg {
	width: 1.125em;
	vertical-align: -12.5%;
	margin-right: 0.3em;
}

.pinned-directories {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}
.pinned-directories > div {
	border: 1px solid #e0e2e4;
	background-color: white;
	padding: 1em;
	border-radius: 3px;
	width: calc(25% - 0.5em);
	box-sizing: border-box;
}
.pinned-directories > div .description {
	margin-top: 0.4em;
}
.pinned-directories > div .path {
	font-size: 0.8em;
	color: #777;
}

.provisioning-info {
	margin-top: 1rem;
}
.provisioning-info .item + .item {
	margin-top: 0.5rem;
}
.provisioning-info .item .header {
	font-weight: bold;
}
.provisioning-info .item .description {
	color: #555;
}

.button {
	height: 3rem;
	padding: 0 1.5rem;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-border-radius: 0.3rem;
	-moz-border-radius: 0.3rem;
	border-radius: 0.3rem;
	background: #00a4d6;
	border: 0.1rem solid rgba(0, 0, 0, 0.25);
	color: white;
	cursor: pointer;
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 2.8rem;
	margin: 0;
	transition: background 200ms ease, transform 100ms ease; }
.button a {
	color: white;
	text-decoration: none;
}
.button:hover, .button:focus {
	background: #008bb6;
	color: white; }
.button:hover a, .button:focus a {
	color: inherit;
	text-decoration: none; }
.button:active {
	transform: scale(0.95);
	color: white; }
.button.basic {
	color: rgba(0, 0, 0, 0.75);
	background: white;
}
.button svg {
	height: 1em;
	margin-right: 0.3em;
	vertical-align: -5.5%;
	display: inline-block;
}

@media only screen and (min-width:768px) {
	.files .file {
		line-height: 26px;
	}
}

@media only screen and (max-width:768px) {
	.files .file {
		display: block;
		border-top: 1px solid #eaecef;
	}
	.files .file > div {
		border: none;
	}
	.files .file .age,
	.files .file .filesize {
		display: block;
		padding-left: 39px;
		font-size: 0.8em;
		line-height: 1.1;
		padding-top: 0;
		padding-bottom: 0;
		color: #888;
	}
	.files .file .ios-tools {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-content: center;
		padding-left: 39px;
		padding-right: 39px;
	}
	.pinned-directories > div {
		width: 100%;
	}
}

.ios-only {
	visibility: hidden; /* Defaults to hidden, only visible on iOS */
}
.ios .ios-only {
	visibility: visible;
}

a { color: #007DA3; }
a:visited { color: #006685; }

#login-form {
	visibility: hidden;
	margin: 40px;
	display: flex;
	align-items: center;
	flex-direction: column;
	flex: 1;

	input {
		margin: 0 0 10px 10px;
		padding: 2px;
		font-size: 1.2em;
	}
}

#logout {
	float: right;
}